import useScrollToTop from '../common/hook/useScrollToTop';

import styles from './legal.module.scss';

const RefundPolicyPage = () => {
  useScrollToTop();

  return (
    <div className={styles.LegalContainer}>
      <h1>Supertone Refund Policy</h1>

      <p>
        All sales are final, and there shall be no refunds except as expressly
        provided in the relevant terms of services or as required by appliacble
        laws.
      </p>
    </div>
  );
};

export default RefundPolicyPage;
